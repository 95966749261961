<template>
    <div>
        <loading :active="deckLoading" />
        <div
            id="overlay"
            v-if="showSuggestionModal || shouldShowLoginModal || shouldShowRewardsModal"
        ></div>
        <div class="ctt-banner" v-show="snackbarMessage.length">{{ snackbarMessage }}</div>
        <deck-header></deck-header>
        <deck-subheader></deck-subheader>
        <main>
            <div class="mt--300 grid-col grid-col--12" style="height: 50px"></div>
            <deck-content></deck-content>
        </main>
        <suggest-new-card-modal v-if="showSuggestionModal"></suggest-new-card-modal>

        <!-- <button class="button button--sm button--primary" style="position: absolute; top: 10px; z-index: 9999;" @click="awardRewardPoints">Fire Custom Event for Points</button> -->

        <login-register-modal v-if="shouldShowLoginModal"></login-register-modal>
        <rewards-modal v-if="shouldShowRewardsModal"></rewards-modal>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {showAt, hideAt} from 'vue-breakpoints'
import Tour from "../../../mixins/Tour";
import LoginRegisterModal from "../sso/LoginRegisterModal";

let originalGuest = '';
let allCards = '';
let guestCards = '';
let deckCategories = '';
let resources = '';

export default {
    name: 'deck-view',
    // props: ['originalGuest', 'allCards', 'guestCards', 'deckCategories', 'resources'],
    mixins: [collectorActions, Tour],
    components: { Loading, hideAt, showAt, LoginRegisterModal },
    beforeCreate() {
        axios
            .get('/fetch-index')
            .then(res => {
                this.$store.commit('SET_GUEST', res.data.guest); // Update Vuex directly
                this.$store.commit('SET_GUEST_CARDS', res.data.guestCards);
                this.$store.commit('SET_DECK_CARDS', res.data.allCards);
                this.$store.commit('SET_DECK_CATEGORIES', res.data.deckCategories);
                this.$store.commit('SET_DECK_RESOURCES', res.data.resources);
            })
            .catch(err => {
                console.log(err);
            });
    },
    mounted() {
        console.log('ORIGINAL', originalGuest)
        this.$store.commit('SET_BACK_TO_FEED', true);
        this.$store.commit('SET_DECK_LOADING', false);
        this.$store.commit('SET_BACK_TO_FEED', false);

        //@todo on page load, relay AARP.store.getState().user.rewardsUser to a new endpoint that will save it to user_rewards column on guests table
        //@todo replace this.guest.user_rewards usages with AARP.store.getState().user.rewardsUser

        console.log('CARE-APP: DeckView.vue: Guest rewards enabled', this.guest.user_rewards);

        // collector action
        this.deckOpen();

        if (!this.tourIsCompleteFor('deck', this.guest.id)) {
            this.startTour();
        }

        if (AARP.store.getState().cookie.fedid && this.getAARPFirstName()) {
            if (this.guest.name !== this.getAARPFirstName()) {
                axios
                    .post('/guest/api/set-guest-name', { name: this.getAARPFirstName() })
                    .then(res => {
                        console.log(res);
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        }

        document.addEventListener('stepClicked', (event) => {
            console.log('Step clicked:', event.detail.stepId);
            // Perform any actions you need when a step is clicked
        });
    },
    data() {
        return {
            overlay: false,
        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            showSuggestionModal: 'showSuggestionModal',
            deckLoading: 'deckLoading',
            snackbarMessage: 'snackbarMessage',
            showLoginModal: 'showLoginModal',
            showRewardsModal: 'showRewardsModal',
            dismissedRewardsModal: 'dismissedRewardsModal',
            dismissedLoginRegisterModal: 'dismissedLoginRegisterModal'
        }),
        shouldShowRewardsModal() {
            return !this.dismissedRewardsModal && this.showRewardsModal;
        },
        shouldShowLoginModal() {
            return !this.dismissedLoginRegisterModal && this.showLoginModal && !this.guest.user_fedid;
        }
    },
    methods: {
        startTour() {
            setTimeout(() => {
                if (!this.isMobile) {
                    this.$nextTick(() => {
                        const tour = this.$shepherd({
                            useModalOverlay: true,
                            defaultStepOptions: {
                                //scrollTo: { behavior: 'smooth', block: 'center' },
                                popperOptions: {
                                    modifiers: [
                                        {name: 'offset', options: {offset: [0, 40]}},
                                    ]
                                },
                                modalOverlayOpeningRadius: 10
                            }
                        });

                        tour.on('complete', () => {
                            this.completeTourForGuest('deck', this.guest.id);
                            //document.documentElement.style.overflow = 'auto';
                        });

                        tour.on('stepClicked', (data) => {
                            console.log('OVDJE SMO', data);
                        })

                        this.getTourStepsForDeck(tour).forEach(step => {
                            tour.addStep(step);
                        });

                        setTimeout(() => {
                            tour.start();
                            //document.documentElement.style.overflow = 'hidden';
                        },500);

                        this.setDataAttributes();
                    });
                }

                if (this.isMobile) {
                    this.$nextTick(() => {
                        const tour = this.$shepherd({
                            useModalOverlay: true,
                            defaultStepOptions: {
                                scrollTo: { behavior: 'smooth', block: 'center' },
                                popperOptions: {
                                    modifiers: [{ name: 'offset', options: { offset: [0, 20] } }]
                                }
                            }
                        });

                        tour.on('complete', () => {
                            this.completeTourForGuest('deck', this.guest.id);
                        });

                        this.getTourStepsForMobileDeck(tour).forEach(step => {
                            tour.addStep(step);
                        });

                        tour.start();
                    });
                }
            }, 100);
        },
        getAARPFirstName() {
            if (AARP !== undefined) {
                if (AARP.store !== undefined) {
                    if (AARP.store.getState() !== undefined) {
                        let object = AARP.store.getState();

                        return _.get(object, 'cookie.at.f');
                    }
                }
            }

            return false;
        }
    },
    watch: {}
};
</script>

<style lang="scss">
.ctt-content {
    padding: 15px 10px;
    position: relative;
    overflow: hidden;
}

.p-width--100 {
    width: 100%;
}
</style>
